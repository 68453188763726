import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from "vuex-multi-tab-state";

import userStore from "@/store/modules/user";
import notificationsStore from "@/store/modules/notifications";
import currenciesStore from "@/store/modules/currencies";
import { RootState } from "@/types/store/RootState";

export default createStore({
  state: {} as RootState,
  mutations: {},
  actions: {},
  modules: {
    user: userStore,
    notifications: notificationsStore,
    currencies: currenciesStore,
  },
  plugins: [
    createPersistedState({ paths: ["user"] }),
    createMultiTabState({
      statesPaths: ["user"],
    }),
  ],
});
